/* eslint-disable sort-keys */
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

import { colorTheme } from './colorTheme';
import {
  firaSansBold,
  firaSansLight,
  firaSansRegular,
  pontiacBold,
  pontiacLight,
  pontiacRegular,
  typographyTheme,
} from './typographyTheme';

const themeOptions: ThemeOptions = {
  palette: {
    type: 'light',
    ...colorTheme,
  },
  typography: typographyTheme,
  overrides: {
    MuiButton: {
      containedPrimary: {
        fontFamily: 'Fira Sans',
        fontSize: '14px',
        fontWeight: 400,
        padding: '10px 20px',
        textTransform: 'capitalize',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*': {
          boxSizing: 'border-box',
          '&::after, &::before': {
            boxSizing: 'border-box',
          },
        },
        '@font-face': [
          firaSansBold,
          firaSansLight,
          firaSansRegular,
          pontiacBold,
          pontiacLight,
          pontiacRegular,
        ],
        a: {
          color: 'inherit',
          textDecoration: 'none',
        },
        body: {
          backgroundColor: colorTheme.greyScale.grey01,
        },
        '.MuiAutocomplete-option': {
          display: 'block !important',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        '.MuiAutocomplete-tag': {
          '& .MuiChip-deleteIcon': {
            height: '14px !important',
            width: '14px !important',
          },
          '& .MuiChip-label': {
            fontFamily: 'Fira Sans !important',
            fontSize: '14px !important',
            padding: '4px 8px !important',
          },
          backgroundColor: `${colorTheme.greyScale.grey02} !important`,
          height: '21px !important',
          padding: '4px 0 !important',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: colorTheme.greyScale.grey03,
        '&$checked': {
          color: `${colorTheme.primary.main} !important`,
        },
      },
    },
    MuiChip: {
      label: {
        fontFamily: 'Pontiac',
        lineHeight: '18px',
      },
    },
    MuiDialog: {
      paper: {
        minWidth: '480px',
        padding: '24px 16px',
      },
    },
    MuiDialogActions: {
      root: {
        marginTop: '40px',
      },
    },
    MuiDialogContent: {
      root: {
        minHeight: '100px',
      },
    },
    MuiFormControl: {
      root: {
        fontSize: '14px',
        lineHeight: '28px',
        minWidth: '188px',
      },
    },
    MuiFormHelperText: {
      root: {
        lineHeight: '14px',
      },
    },
    MuiFormLabel: {
      root: {
        '&[data-shrink="true"]': {
          transform: 'translate(14px, -6px) scale(0.875) !important',
        },
        fontSize: '14px',
      },
    },
    MuiInputBase: {
      multiline: {
        height: 'unset',
      },
      root: {
        '& legend': {
          fontSize: '12px',
        },
        fontSize: '14px',
        height: '34px',
        lineHeight: '28px',
        minWidth: '188px',
      },
    },
    MuiListItemText: {
      secondary: {
        color: colorTheme.greyScale.darkGrey,
      },
    },
    MuiMenu: {
      list: {
        boxShadow: '0px 6px 12px rgba(25, 26, 29, 0.08) !important',
      },
    },
    MuiPaper: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiSelect: {
      select: {
        fontSize: '14px',
        lineHeight: '28px',
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: colorTheme.primary.main,
        height: '3px',
      },
      root: {
        '&::after': {
          backgroundColor: colorTheme.grey[25],
          bottom: 0,
          content: "''",
          height: '3px',
          position: 'absolute',
          width: '100%',
          zIndex: 0,
        },
        marginBottom: '16px',
        minHeight: '36px',
        position: 'relative',
      },
      scroller: {
        position: 'relative',
        zIndex: 1,
      },
    },
    MuiTab: {
      root: {
        '&.Mui-selected': {
          fontFamily: 'Pontiac',
          fontWeight: 'bold',
        },
        '&:hover:not(.Mui-selected)': {
          background: 'rgba(0, 0, 0, 0.04)',
          opacity: 1,
          transition: 'all 0.2s',
        },
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        fontFamily: 'Fira Sans',
        fontWeight: 'normal',
        minHeight: '36px',
        textTransform: 'none',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: 'none',
      },
    },
    MuiTablePagination: {
      input: {
        minWidth: 'initial',
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiDialog: {
      disableBackdropClick: true,
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiTypography: {
      display: 'block',
    },
  },
};

export const muiTheme = createMuiTheme(themeOptions);
