/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  useCallback,
  useMemo,
  useState,
} from 'react';
import dayjs from 'dayjs';
import {
  IconButton,
  Typography,
  Zoom,
} from '@material-ui/core';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import CloseIcon from '@material-ui/icons/Close';

import { useAppDispatch } from '@/core/store/store';

import {
  deleteNotification,
  setNotificationAsRead,
} from '@/features/Notification/store';
import {
  setExpandLogComments,
  setScrollIntoLogId,
} from '@/features/Project/store';
import {
  notificationTemplate,
  SimpleNotification,
} from '@/features/Notification/interfaces';

import { dateFormats } from '@/utils/dateFormats';
import { getSafeHTML } from '@/utils/helpers';

import { useStyles } from './Notification.styles';

interface NotificationProps {
  data: SimpleNotification;
  index: number;
  onDelete: (index: number) => void;
}

const getIconName = (template: notificationTemplate) => {
  switch (template) {
    case notificationTemplate.PROJECT_STALE:
      return 'refresh';

    case notificationTemplate.MENTION_RISK:
    case notificationTemplate.MENTION_OPPORTUNITY:
    case notificationTemplate.COMMENT_RISK:
    case notificationTemplate.COMMENT_OPPORTUNITY:
    case notificationTemplate.COMMENT_OBSERVED_RISK:
    case notificationTemplate.COMMENT_OBSERVED_OPPORTUNITY:
      return 'comment';

    case notificationTemplate.UPDATE_OBSERVED:
      return 'binoculars';

    default:
      return 'info';
  }
};

export const Notification = ({
  data,
  index,
  onDelete,
}: NotificationProps) => {
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useAppDispatch();

  const {
    objectId,
    commentedObjectId,
    id,
    notificationTemplate: template,
    objectName,
    message,
    isRead,
    createdAt,
  } = data;

  const [willBeDeleted, setWillBeDeleted] = useState(false);
  const notificationDate = useMemo(() => dayjs(createdAt).format(`${dateFormats.americanMonthAbbr}, ${dateFormats.timeShort}`), [createdAt]);
  const saferHTMLMessage = useMemo(() => getSafeHTML(message), [message]);

  const handleNotificationClick = useCallback(() => {
    if (commentedObjectId) {
      dispatch(setExpandLogComments(true));
      dispatch(setScrollIntoLogId(commentedObjectId));
    }

    router.push(`/projects/${objectId}`);

    if (!isRead) {
      dispatch(setNotificationAsRead(id));
    }
  }, [
    commentedObjectId,
    isRead,
    id,
    dispatch,
    objectId,
    router,
  ]);

  const handleDeleteNotification = useCallback(e => {
    e.stopPropagation();

    setWillBeDeleted(true);
    setTimeout(() => {
      dispatch(deleteNotification(id));
      onDelete(index);
    }, 100);
  }, [
    id,
    dispatch,
    index,
    onDelete,
  ]);

  const iconName = getIconName(template);

  const notificationClassNames = classNames(
    classes.notificationContainer,
    {
      [classes.notificationUnread]: !isRead,
    }
  );

  return (
    <Zoom
      in={!willBeDeleted}
      timeout={{
        enter: 200,
        exit: 100,
      }}
    >
      <div
        key={id}
        className={notificationClassNames}
        role="button"
        tabIndex={0}
        onClick={handleNotificationClick}
      >
        <div className={classes.notificationIcon}>
          <img
            src={`/images/${iconName}.svg`}
            alt={`Icon ${iconName}`}
          />
        </div>
        <div
          className={classes.notificationDetails}
        >
          <Typography
            className={classes.notificationHeader}
            variant="h5"
            title={objectName}
          >
            {objectName}
            { !isRead && <div className={classes.notificationReadIndicator} />}
          </Typography>
          <Typography
            className={classes.notificationMessage}
            dangerouslySetInnerHTML={{ __html: saferHTMLMessage }}
            variant="body2"
          />
          <Typography
            className={classes.notificationTime}
          >
            {notificationDate}
          </Typography>
        </div>
        <div className={classes.notificationAction}>
          <IconButton
            className={classes.notificationDelete}
            size="small"
            onClick={handleDeleteNotification}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </Zoom>
  );
};
