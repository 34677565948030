import {
  useCallback, useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import Alert, { Color } from '@material-ui/lab/Alert';

import { useAppDispatch } from '@/core/store/store';

import {
  GlobalMessage as GlobalMessageProps, GlobalMessageType,
} from '../../interfaces';
import { removeGlobalMessage } from '../../store';
import { useStyles } from './GlobalMessage.styles';

const TIMEOUT = 4000;

export const GlobalMessage = ({
  id,
  message,
  type,
}: GlobalMessageProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useAppDispatch();
  const classes = useStyles(isVisible);

  const handleClose = useCallback(() => dispatch(removeGlobalMessage(id)), [dispatch, id]);

  const getSeverity: Color = useMemo((): Color => {
    switch (type) {
      case GlobalMessageType.ERROR:
        return 'error';
      case GlobalMessageType.WARN:
        return 'warning';
      case GlobalMessageType.SUCCESS:
        return 'success';
      default:
        return 'info';
    }
  }, [type]);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, TIMEOUT);

    return () => {
      clearTimeout(timer);
    };
  }, [handleClose]);

  useLayoutEffect(() => {
    const animation = setTimeout(() => setIsVisible(true), 100);

    return () => {
      clearTimeout(animation);
    };
  }, []);

  return (
    <Alert
      onClose={handleClose}
      severity={getSeverity}
      className={classes.alert}
    >
      {message}
    </Alert>
  );
};
