import React from 'react';
import { useRouter } from 'next/router';

import { MainMenu } from '@/features/MainMenu';

import { useStyles } from './Layout.styles';
import { Drawer } from '../Drawer';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const router = useRouter();
  const classes = useStyles();

  const hideDrawer = router.pathname === '/projects/edit/[id]' || router.pathname === '/';

  return (
    <div className={classes.pageWrapper}>
      {!hideDrawer && <Drawer />}

      <div className={classes.contentWrapper}>
        <MainMenu />

        <main>
          {children}
        </main>
      </div>
    </div>
  );
};
