import { makeStyles } from '@material-ui/core';

import { colorTheme } from '@/themes/colorTheme';

export const useStyles = makeStyles(theme => ({
  notificationAction: {
    width: '44px',
  },
  notificationContainer: {
    '&$notificationUnread': {
      '& $notificationHeader $notificationReadIndicator': {
        display: 'block',
      },
      background: colorTheme.orangeScale[5],
    },
    '&:hover': {
      '& $notificationHeader': {
        color: theme.palette.primary.main,
      },
      border: `1px solid ${colorTheme.orangeScale[50]}`,
    },
    alignItems: 'flex-start',
    border: '1px solid transparent',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    marginBottom: '8px',
    transition: 'all 0.2s',
  },
  notificationDelete: {
    color: '#000',
    margin: '7px',
  },
  notificationDetails: {
    overflow: 'hidden',
    padding: '12px 0',
  },
  notificationHeader: {
    lineHeight: '20px',
    marginBottom: '4px',
    overflow: 'hidden',
    paddingRight: '20px',
    position: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  notificationIcon: {
    alignItems: 'center',
    display: 'flex',
    flex: '0 0 22px',
    height: '22px',
    justifyContent: 'center',
    margin: '12px 14px',
  },
  notificationMessage: {
    fontSize: '14px',
    lineHeight: '24px',
    marginBottom: '4px',
  },
  notificationReadIndicator: {
    background: colorTheme.success.main,
    borderRadius: '50%',
    content: '" "',
    display: 'none',
    height: '8px',
    position: 'absolute',
    right: '10px',
    top: '6px',
    width: '8px',
  },
  notificationTime: {
    color: theme.palette.secondary.main,
    fontSize: '10px',
    lineHeight: '12px',
  },
  notificationUnread: {
  },
}));
