import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
  },
  pageHeader: {
    position: 'sticky',
    top: 0,
  },
  pageWrapper: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
  },
}));
