import { Fragment } from 'react';
import classnames from 'classnames';
import {
  Collapse,
  List,
  ListItem,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useRouter } from 'next/router';

import {
  Nullable,
  SimpleDetails,
} from '@/core/interfaces/common';
import { Link } from '@/core/components/Link';

import { CategoryStatus } from '@/features/Project/interfaces';

import { ProjectListItem } from '../ProjectListItem';
import {
  ArchiveButton, useStyles,
} from '../../Drawer.styles';

interface PmListTypes {
  activeProjectManager: Nullable<string>;
  fullName: string;
  handleArchiveToggle: () => void;
  handleClick: (id: string | null) => void;
  handleCollapse: () => void;
  handleProjectChange: () => void;
  id: string;
  isArchiveOpen: boolean;
  isPmAscending: boolean;
  projectRefs: any;
  projects: Array<SimpleDetails>;
  refs: any;
}

export const PmListItem = ({
  activeProjectManager,
  fullName,
  handleArchiveToggle,
  handleClick,
  handleCollapse,
  handleProjectChange,
  id,
  isArchiveOpen,
  isPmAscending,
  projectRefs,
  projects,
  refs,
}: PmListTypes) => {
  const classes = useStyles();
  const router = useRouter();
  const {
    query: {
      id: paramId,
    },
    pathname,
  } = router;

  const isActive = pathname === '/profile/[id]' && activeProjectManager === id;

  const projectsActive = projects.filter(
    pmProject => (pmProject.category !== CategoryStatus.CLOSED && !pmProject.archived)
  );
  const isOpen = isActive || projectsActive
    .some(({ id: projectId }) => projectId === Number(paramId));
  const projectsClosed = projects.filter(
    pmProject => (pmProject.category === CategoryStatus.CLOSED || pmProject.archived)
  );
  const isProjectActive = (projectId: number) => projectId === Number(paramId);

  const itemClasses = classnames(
    classes.item,
    {
      [classes.itemActive]: isActive,
    }
  );

  return (
    <Fragment key={fullName}>
      <ListItem
        className={itemClasses}
        onClick={() => handleClick(isActive ? null : id)}
        ref={(ref: HTMLLIElement) => { refs.current[id] = ref; }}
      >
        <Link
          href={`/profile/${id}`}
          className={classes.itemLink}
        >
          {isActive ?
            <KeyboardArrowDownIcon className={classes.icon} /> :
            <ChevronRightIcon className={classes.icon} />}
          <span className={classes.itemText}>
            {fullName}
          </span>
        </Link>
        <Collapse
          addEndListener={handleCollapse}
          in={isOpen}
          timeout={0}
          unmountOnExit
          className={classes.collapse}
        >
          <List
            component="ul"
            className={classes.sublist}
          >
            {projectsActive.map(({
              name,
              id: projectId,
              isObservedByUser,
            }) => {
              const projectItemClasses = classnames(
                classes.item,
                {
                  [classes.itemActive]: isProjectActive(projectId),
                }
              );

              return (
                <ProjectListItem
                  classNames={projectItemClasses}
                  isObservedByUser={isObservedByUser}
                  isPmAscending={isPmAscending}
                  key={projectId}
                  name={name}
                  onClick={handleProjectChange}
                  projectId={projectId}
                  ref={(ref: HTMLButtonElement) => { projectRefs.current[projectId] = ref; }}
                />
              );
            })}
          </List>
          {
            projectsClosed.length > 0 && (
              <>
                <ArchiveButton
                  variant="text"
                  onClick={handleArchiveToggle}
                >
                  {isArchiveOpen ?
                    <KeyboardArrowDownIcon className={classes.icon} /> :
                    <ChevronRightIcon className={classes.icon} />}
                  Show archived
                </ArchiveButton>
                <Collapse
                  in={isArchiveOpen}
                  timeout={0}
                  unmountOnExit
                  className={classes.collapse}
                >
                  <List
                    component="ul"
                    className={classes.sublist}
                  >
                    {projectsClosed.map(({
                      name,
                      id: projectId,
                      isObservedByUser,
                    }) => {
                      const projectItemClasses = classnames(
                        classes.item,
                        {
                          [classes.itemActive]: isProjectActive(projectId),
                        }
                      );

                      return (
                        <ProjectListItem
                          classNames={projectItemClasses}
                          isObservedByUser={isObservedByUser}
                          isPmAscending={isPmAscending}
                          key={projectId}
                          name={name}
                          onClick={handleProjectChange}
                          projectId={projectId}
                          ref={
                            (ref: HTMLButtonElement) => { projectRefs.current[projectId] = ref; }
                          }
                        />
                      );
                    })}
                  </List>
                </Collapse>
              </>
            )
          }
        </Collapse>
      </ListItem>
    </Fragment>
  );
};
