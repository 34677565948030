import {
  makeStyles,
  Theme,
} from '@material-ui/core';

export interface MainMenuTypes {
  hasBackButton?: boolean;
}

export const useStyles = makeStyles<Theme, MainMenuTypes>(theme => ({
  goBackButton: {
    fontFamily: 'Fira Sans',
    textTransform: 'capitalize',
  },
  notificationsButton: {
    color: '#fff',
    marginRight: '10px',
  },
  notificationsChip: {
    '&$notificationsChipLight': {
      backgroundColor: '#fff',
      borderColor: '#fff',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  notificationsChipLight: {
  },
  toolbar: {
    justifyContent: ({ hasBackButton }) => (hasBackButton ? 'space-between' : 'flex-end'),
  },
  userEmail: {
    fontSize: '12px',
  },
}));
