import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ palette }) => ({
  formControl: {
    margin: '0 auto',
  },
  searchClear: {
    position: 'absolute',
    right: '4px',
    top: '4px',
  },
  showObservedLabel: {
    '& span.MuiFormControlLabel-label': {
      fontSize: '14px',
      position: 'relative',
      top: '1px',
    },
    '&:after': {
      backgroundImage: 'url(\'/images/star-fill.svg\')',
      backgroundSize: '14px 14px',
      content: '\'\'',
      display: 'block',
      height: '14px',
      marginLeft: '10px',
      width: '14px',
    },
    paddingLeft: '24px',
  },
  sortSelect: {
    '& .MuiSelect-select:focus': {
      backgroundColor: 'white',
    },
    color: `${palette.grey[75]}`,
    height: '34px',
    width: '188px',
  },
  tickSize: {
    transform: 'scale(0.75)',
  },
}));
