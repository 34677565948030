import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  alert: isActive => ({
    alignItems: 'center',
    marginTop: '2px',
    opacity: isActive ? 1 : 0,
    transform: `translateX(${isActive ? 0 : '-10vh'})`,
    transition: 'all 0.5s ease-in-out',
  }),
}));
