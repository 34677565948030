import App, { AppContext } from 'next/app';
import Head from 'next/head';
import cookie from 'cookie';
import jwtDecode from 'jwt-decode';
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';

import {
  AppThunkDispatch,
  wrapper,
} from '@/core/store/store';
import { Layout } from '@/core/components/Layout';
import { DrawerContextProvider } from '@/core/hooks/DrawerContextProvider';

import {
  getUser,
  isUserLoggedSelector,
  logout,
} from '@/features/Auth/store';
import {
  JWTPayload,
  TokenTypes,
} from '@/features/Auth/interfaces';
import { GlobalMessagesContainer } from '@/features/GlobalMessage/components';

import { request } from '@/utils/request';
import {
  getToken,
  isTokenExpired,
} from '@/utils/jwt';
import { redirect } from '@/utils/helpers';

import { muiTheme } from '@/themes/material-ui';

type MerixComponent = AppContext['Component'] & { isRestricted?: boolean };
type MerixAppContext = Omit<AppContext, 'Component'> & { Component: MerixComponent };

class MyApp extends App {
  static getInitialProps = wrapper.getInitialAppProps(store => async ({
    Component,
    ctx,
  }: MerixAppContext) => {
    const {
      req,
      res,
    } = ctx;
    const { dispatch }: { dispatch: AppThunkDispatch } = store;
    let isUserLogged = false;

    if (req) {
      let accessToken = null;
      let refreshToken = null;

      if (req.headers.cookie) {
        const cookies = cookie.parse(req.headers.cookie);

        accessToken = cookies.access || null;
        refreshToken = cookies.refresh || null;

        if (refreshToken) {
          request.setRefreshToken(refreshToken);
        }
      }

      if (refreshToken && !accessToken) {
        try {
          const { access } = await request.getNewToken();
          const { exp: accessExpiration } = jwtDecode<JWTPayload>(access);

          const accessTokenCookie = cookie.serialize(
            TokenTypes.ACCESS, access,
            { expires: new Date(accessExpiration * 1000) }
          );

          // eslint-disable-next-line no-unused-expressions
          res?.setHeader('Set-Cookie', accessTokenCookie);

          accessToken = access;
        } catch (e) {
          const refreshTokenCookie = cookie.serialize(
            TokenTypes.REFRESH, refreshToken,
            { expires: new Date() }
          );

          // eslint-disable-next-line no-unused-expressions
          res?.setHeader('Set-Cookie', refreshTokenCookie);
        }
      }

      // TODO: Temp fix for problems with logged in users without tokens
      if (!accessToken) {
        await dispatch(logout());
      }

      if (accessToken && !isTokenExpired(accessToken)) {
        request.setAuthorizationToken(accessToken);

        const resultAction = await dispatch(getUser());

        if (getUser.fulfilled.match(resultAction)) {
          const user = resultAction.payload;

          isUserLogged = !!user.id;
        } else {
          request.removeAuthorizationToken();
        }
      }

      if (isUserLogged && accessToken === null) {
        dispatch(logout());
      }
    }

    const state = store.getState();

    isUserLogged = isUserLoggedSelector(state);

    // Redirect user if not logged and page is restricted
    if (!isUserLogged && Component.isRestricted) {
      redirect(res, `/?next=${req?.url}`);

      return { pageProps: {} };
    }

    // Keep nested components `getInitialProps` at the end
    const componentInitialProps = Component.getInitialProps ?
      await Component.getInitialProps(ctx) :
      {};

    if (req) {
      // Keep remove authorization token at the end of `getInitialProps`
      request.removeAuthorizationToken();
      request.setRefreshToken(null);
    }

    return {
      pageProps: {
        ...componentInitialProps,
      },
      pathname: req?.url || ctx.pathname,
    };
  })

  async componentDidMount() {
    dayjs.extend(relativeTime);

    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles?.parentElement?.removeChild(jssStyles);
    }

    const accessToken = getToken(TokenTypes.ACCESS);

    if (accessToken) {
      const isTokenValid = !isTokenExpired(accessToken);

      if (isTokenValid) {
        request.setAuthorizationToken(accessToken);
      }
    }
  }

  render() {
    const {
      Component,
      pageProps,
    } = this.props;

    return (
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={muiTheme}>
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1"
              />
              <title key="title">Risk Management Tool</title>
            </Head>
            <DrawerContextProvider>
              <CssBaseline />
              <Layout>
                <GlobalMessagesContainer />
                <Component {...pageProps} />
              </Layout>
            </DrawerContextProvider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </StylesProvider>
    );
  }
}

export default wrapper.withRedux(MyApp);
