import {
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/core/store/store';
import { LoadingIndicator } from '@/core/components/LoadingIndicator';

import {
  SimpleNotification,
} from '@/features/Notification/interfaces';
import {
  getNextPage,
  getTotalNotifications,
} from '@/features/Notification/store/selectors';
import {
  getNotifications,
} from '@/features/Notification/store';
import { Notification } from '@/features/Notification/components';

import { useStyles } from './NotificationDropdown.styles';

interface NotificationDropdownProps {
  anchorEl: HTMLButtonElement | null;
  handleClickAway: () => void;
  notifications: Array<SimpleNotification>;
}

const PAGE_SIZE = 20;

export const NotificationDropdown = ({
  anchorEl,
  handleClickAway,
  notifications,
}: NotificationDropdownProps) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const notificationsTotalCount = useSelector(getTotalNotifications);
  const nextPageURL = useSelector(getNextPage);

  const hasMoreNotifications = notifications.length < notificationsTotalCount && nextPageURL;

  const onDelete = (index: number) => {
    dispatch(getNotifications({
      page: Math.floor(index / PAGE_SIZE) + 1,
      pageSize: PAGE_SIZE,
    }));
  };

  const { ref } = useInView({
    onChange: inView => {
      if (inView) {
        dispatch(getNotifications({
          page: Math.floor(notifications.length / PAGE_SIZE) + 1,
          pageSize: PAGE_SIZE,
        }));
      }
    },
    threshold: 0,
  });

  return (
    <Popper
      anchorEl={anchorEl}
      className={classes.popper}
      open
      placement="bottom-end"
      transition
      modifiers={{ offset: { offset: '-8,5' } }}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Fade
            {...TransitionProps}
            timeout={200}
          >
            <Paper className={classes.popperPaper}>
              {notifications.length > 0 ?
                (
                  <>
                    {notifications.map((notification, index) => (
                      <Notification
                        key={notification.id}
                        data={notification}
                        onDelete={onDelete}
                        index={index}
                      />
                    ))}
                    { hasMoreNotifications && <div ref={ref}><LoadingIndicator /></div>}
                  </>
                ) :
                (
                  <Typography
                    className={classes.text}
                    variant="body2"
                  >
                    No notifications.
                  </Typography>
                )}
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};
