import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { debounce } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  Collapse,
  Divider,
  List,
  Drawer as MuiDrawer,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {
  ActiveDrawerOption,
  RequestStatus,
} from '@/core/interfaces/common';
import { useAppDispatch } from '@/core/store/store';
import { useDrawerContext } from '@/core/hooks/DrawerContextProvider';
import { LoadingIndicator } from '@/core/components/LoadingIndicator';

import {
  clearProjectManagersProjectsList,
  getActiveProjectManager,
  getProjectManagersList,
  getProjectManagersListStatusSelector,
  selectAllProjectManagers,
  setActiveProjectManager,
} from '@/features/ProjectManager/store';
import {
  getProjectNameList,
  getProjectNamesListStatusSelector,
  selectAllProjectNames,
} from '@/features/Project/store';
import { clearRisksList } from '@/features/Risk/store';
import { clearOpportunitiesList } from '@/features/Opportunity/store';
import { CategoryStatus } from '@/features/Project/interfaces';

import {
  ArchiveButton,
  DrawerTab,
  DrawerTabs,
  useStyles,
} from './Drawer.styles';
import { ProjectListItem } from './components/ProjectListItem';
import { PmListItem } from './components/PmListItem';
import { DrawerForm } from './components/DrawerForm';

export const Drawer = () => {
  const classes = useStyles();

  const [isPmAscending, setIsPmAscending] = useState(true);
  const [isProjectAscending, setIsProjectAscending] = useState(true);
  const [openArchive, setOpenArchive] = useState(false);
  const [openArchivedProjects, setOpenArchivedProjects] = useState(false);

  const [sortDirection, setSortDirection] = useState('ascending');

  const {
    activeDrawerOption,
    drawerPMSearchValue,
    drawerProjectSearchValue,
    setActiveDrawerOption,
    setDrawerPMSearchValue,
    setDrawerProjectSearchValue,
    showObservedProjects,
    setShowObservedProjects,
  } = useDrawerContext();

  const refs = useRef<Array<HTMLButtonElement | null>>([]);
  const projectRefs = useRef<Array<HTMLButtonElement | null>>([]);
  const drawerListRef = useRef<HTMLUListElement | null>(null);

  const dispatch = useAppDispatch();
  const router = useRouter();
  const {
    query: {
      id: paramId,
    },
  } = router;

  const projectManagers = useSelector(selectAllProjectManagers);
  const projectManagersStatus = useSelector(getProjectManagersListStatusSelector);
  const projectNames = useSelector(selectAllProjectNames);
  const projectNamesStatus = useSelector(getProjectNamesListStatusSelector);
  const activeProjectManager = useSelector(getActiveProjectManager);

  const activeDrawerSearchvalue =
    activeDrawerOption === ActiveDrawerOption.ALL_PROJECTS ?
      drawerProjectSearchValue :
      drawerPMSearchValue;

  const handleScrollIntoView = useCallback(
    () => {
      if (!activeProjectManager) {
        const firstElement = Object.values(refs.current)[0];

        return firstElement?.scrollIntoView(({
          behavior: 'smooth',
          block: 'start',
        }));
      }

      return refs?.current?.[activeProjectManager]?.scrollIntoView(({
        behavior: 'smooth',
        block: 'start',
      }));
    },
    [activeProjectManager]
  );

  const handleProjectScrollIntoView = useCallback(
    () => {
      if (!paramId && drawerListRef?.current) {
        return drawerListRef?.current.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: 0,
        });
      }

      return projectRefs?.current?.[paramId as string]?.scrollIntoView(({
        behavior: 'smooth',
        block: 'start',
      }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paramId]
  );

  const debouncedSearchProjects = useMemo(
    () => debounce(async val => {
      await dispatch(getProjectNameList({
        ordering: isProjectAscending ? 'name' : '-name',
        search: val,
        showOnlyObserved: showObservedProjects,
      }));

      handleProjectScrollIntoView();
    }, 400),
    [
      dispatch,
      handleProjectScrollIntoView,
      isProjectAscending,
      showObservedProjects,
    ]
  );

  const debouncedSearchPM = useMemo(
    () => debounce(async val => {
      await dispatch(getProjectManagersList({
        ordering: isPmAscending ? 'full_name' : '-full_name',
        search: val,
      }));

      handleScrollIntoView();
    }, 400),
    [
      dispatch,
      handleScrollIntoView,
      isPmAscending,
    ]
  );

  const search = useCallback(
    query => {
      if (activeDrawerOption === ActiveDrawerOption.PM) {
        setDrawerPMSearchValue(query);
        debouncedSearchPM(query);
      } else {
        setDrawerProjectSearchValue(query);
        debouncedSearchProjects(query);
      }
    },
    [
      activeDrawerOption,
      debouncedSearchPM,
      debouncedSearchProjects,
      setDrawerPMSearchValue,
      setDrawerProjectSearchValue,
    ]
  );

  const handleActiveDrawerOptionChange = useCallback(
    (event: BaseSyntheticEvent, newValue: number) => {
      setActiveDrawerOption(newValue);
      setIsPmAscending(true);
      setIsProjectAscending(true);
      setSortDirection('ascending');
    },
    [setActiveDrawerOption]
  );

  const handleProjectManagerClick = useCallback(
    id => {
      if (id) {
        dispatch(setActiveProjectManager(id));
        setOpenArchive(false);
      }

      if (id && paramId !== id) {
        dispatch(clearProjectManagersProjectsList());
      }
    },
    [dispatch, paramId]
  );

  const isProjectActive = (projectId: number) => projectId === Number(paramId);

  const handlePmSort = () => setIsPmAscending(prevState => !prevState);
  const handleProjectSort = () => setIsProjectAscending(prevState => !prevState);
  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      search(event.target.value);
    },
    [search]
  );

  const handleSearchClear = useCallback(() => search(''), [search]);

  const handleShowObserved = () => setShowObservedProjects(prevState => !prevState);

  const handleProjectChange = () => {
    dispatch(clearRisksList());
    dispatch(clearOpportunitiesList());
  };

  const handleArchiveToggle = useCallback(
    () => setOpenArchive(!openArchive),
    [openArchive]
  );

  const handleArchiveProjectsToggle = useCallback(
    () => setOpenArchivedProjects(!openArchivedProjects),
    [openArchivedProjects]
  );

  const handleSort = (event: BaseSyntheticEvent) => {
    const sortType = event?.target.value;

    setSortDirection(sortType);

    if (activeDrawerOption === ActiveDrawerOption.PM) {
      return handlePmSort();
    }

    return handleProjectSort();
  };

  useEffect(() => {
    if (router.pathname === '/dashboard') {
      handleSearchClear();

      const firstElementId = Object.keys(refs?.current)[0];

      refs?.current[firstElementId]?.scrollIntoView(({
        behavior: 'smooth',
        block: 'start',
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  useEffect(() => {
    dispatch(getProjectManagersList({
      ordering: isPmAscending ? 'full_name' : '-full_name',
      search: drawerPMSearchValue,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isPmAscending]);

  useEffect(() => {
    dispatch(getProjectNameList({
      ordering: isProjectAscending ? 'name' : '-name',
      search: drawerProjectSearchValue,
      showOnlyObserved: showObservedProjects,
    })).then(() => {
      handleProjectScrollIntoView();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    isProjectAscending,
    showObservedProjects,
  ]);

  useEffect(() => {
    if (activeDrawerOption === ActiveDrawerOption.PM) {
      handleScrollIntoView();
    } else {
      handleProjectScrollIntoView();
    }
  }, [
    activeDrawerOption,
    handleProjectScrollIntoView,
    handleScrollIntoView,
  ]);

  const isLoadingPms = projectManagersStatus === RequestStatus.LOADING;
  const isLoadingProjects = projectNamesStatus === RequestStatus.LOADING;

  const projectsActive = projectNames.filter(
    project => project.category !== CategoryStatus.CLOSED && !project.archived
  );
  const projectsClosed = projectNames.filter(
    project => project.category === CategoryStatus.CLOSED || project.archived
  );

  return (
    <MuiDrawer
      variant="permanent"
      anchor="left"
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Link href="/">
        <img
          alt="logo"
          className={classes.logo}
          src="/images/logo.svg"
        />
      </Link>

      <DrawerTabs
        centered
        indicatorColor="primary"
        onChange={handleActiveDrawerOptionChange}
        textColor="inherit"
        value={activeDrawerOption}
      >
        <DrawerTab label="PM" />
        <DrawerTab label="Project" />
      </DrawerTabs>

      <DrawerForm
        drawerSearchValue={activeDrawerSearchvalue}
        handleSearchChange={handleSearchChange}
        handleSearchClear={handleSearchClear}
        handleShowObserved={handleShowObserved}
        handleSort={handleSort}
        isProjectList={activeDrawerOption === ActiveDrawerOption.ALL_PROJECTS}
        showObservedProjects={showObservedProjects}
        sortDirection={sortDirection}
      />

      <Divider className={classes.divider} />

      {(isLoadingPms || isLoadingProjects) ?
        (
          <LoadingIndicator />
        ) :
        (
          <nav
            className={classes.drawerListWrapper}
          >
            <List
              component="ul"
              id="drawerList"
              ref={drawerListRef}
            >
              {(activeDrawerOption === ActiveDrawerOption.PM) && projectManagers.map(({
                fullName,
                id,
                projects,
              }) => (
                <PmListItem
                  activeProjectManager={activeProjectManager}
                  fullName={fullName}
                  handleArchiveToggle={handleArchiveToggle}
                  handleClick={handleProjectManagerClick}
                  handleCollapse={handleScrollIntoView}
                  handleProjectChange={handleProjectChange}
                  id={id}
                  isArchiveOpen={openArchive}
                  isPmAscending={isPmAscending}
                  key={id}
                  projectRefs={projectRefs}
                  projects={projects}
                  refs={refs}
                />
              ))}

              {(activeDrawerOption === ActiveDrawerOption.ALL_PROJECTS) && projectsActive.map(({
                name,
                id: projectId,
                isObservedByUser,
              }) => {
                const listItemClasses = classnames(
                  classes.item,
                  {
                    [classes.itemActive]: isProjectActive(projectId),
                  }
                );

                return (
                  <ProjectListItem
                    classNames={listItemClasses}
                    isObservedByUser={isObservedByUser}
                    key={projectId}
                    name={name}
                    projectId={projectId}
                    ref={(ref: HTMLButtonElement) => { projectRefs.current[projectId] = ref; }}
                  />
                );
              })}
            </List>
            {(activeDrawerOption === ActiveDrawerOption.ALL_PROJECTS) && (
              <>
                <ArchiveButton
                  variant="text"
                  onClick={handleArchiveProjectsToggle}
                >
                  {openArchivedProjects ?
                    <KeyboardArrowDownIcon className={classes.icon} /> :
                    <ChevronRightIcon className={classes.icon} />}
                  Show archived
                </ArchiveButton>
                <Collapse
                  in={openArchivedProjects}
                  timeout={0}
                  unmountOnExit
                >
                  <List
                    component="ul"
                    className={classes.sublist}
                  >
                    {projectsClosed.map(({
                      name, id: projectId, isObservedByUser,
                    }) => {
                      const listItemClasses = classnames(
                        classes.item,
                        {
                          [classes.itemActive]: isProjectActive(projectId),
                        }
                      );

                      return (
                        <ProjectListItem
                          classNames={listItemClasses}
                          isObservedByUser={isObservedByUser}
                          key={projectId}
                          name={name}
                          projectId={projectId}
                          ref={
                            (ref: HTMLButtonElement) => { projectRefs.current[projectId] = ref; }
                          }
                        />
                      );
                    })}
                  </List>
                </Collapse>
              </>
            )}
          </nav>
        )
      }
    </MuiDrawer>
  );
};
