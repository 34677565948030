import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const DEV_ENV = process.env.NODE_ENV === 'development';
const PRODUCTION_ENV = process.env.NODE_ENV === 'production';

Sentry.init({
  dsn: SENTRY_DSN,
  enabled: PRODUCTION_ENV || !DEV_ENV,
  tracesSampleRate: 1.0,
});
