import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { GoogleLogout } from 'react-google-login';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import {
  Button,
  Chip,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  ArrowBack,
  KeyboardArrowDown,
} from '@material-ui/icons';
import NotificationsIcon from '@material-ui/icons/Notifications';
import classnames from 'classnames';

import { useDrawerContext } from '@/core/hooks/DrawerContextProvider';
import { useAppDispatch } from '@/core/store/store';
import { ActiveDrawerOption } from '@/core/interfaces/common';

import { NotificationDropdown } from '@/features/Notification/components/NotificationDropdown';
import { setActiveProjectManager } from '@/features/ProjectManager/store';
import {
  clearNotificationsList,
  getNotifications,
  selectAllNotifications,
} from '@/features/Notification/store';
import { getTotalUnreadNotifications } from '@/features/Notification/store/selectors';

import { redirect } from '@/utils/helpers';

import {
  getUserSelector, isUserLoggedSelector, logout,
} from '../Auth/store';
import { useStyles } from './MainMenu.styles';

const { publicRuntimeConfig } = getConfig() || {};
const { GOOGLE_CLIENT_ID } = publicRuntimeConfig || process.env;

export const MainMenu = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false);

  const router = useRouter();
  const menuButton = useRef(null);
  const hasBackButton = router.pathname === '/projects/[id]' || router.pathname === '/profile/[id]';
  const fetchInterval = useRef<number>();

  const classes = useStyles({ hasBackButton });
  const dispatch = useAppDispatch();
  const { setActiveDrawerOption } = useDrawerContext();

  const user = useSelector(getUserSelector);
  const isLogged = useSelector(isUserLoggedSelector);
  const notifications = useSelector(selectAllNotifications);
  const unreadNotificationCount = useSelector(getTotalUnreadNotifications);

  const handleNotificationDropdownClose = useCallback(() => {
    dispatch(clearNotificationsList({ keepUnreadCount: true }));
    setIsNotificationDropdownOpen(false);
  }, [dispatch]);

  const handleNotificationDropdownToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isNotificationDropdownOpen) {
      setAnchorEl(event.currentTarget);
      setIsNotificationDropdownOpen(true);
    } else {
      handleNotificationDropdownClose();
    }
  };
  const handleProjectManagerClear = () => {
    dispatch(setActiveProjectManager(null));
    setActiveDrawerOption(ActiveDrawerOption.PM);
  };

  const handleMenuButtonClick = useCallback(() => setIsMenuVisible(true), []);
  const handleMenuClose = useCallback(() => setIsMenuVisible(false), []);
  const handleLogout = useCallback(async () => {
    handleProjectManagerClear();
    handleMenuClose();
    await dispatch(logout());
    redirect(null, '/');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, handleMenuClose]);

  const handleGoToDashboard = useCallback(async () => {
    await router.push('/dashboard');
    dispatch(setActiveProjectManager(null));
  }, [dispatch, router]);

  const isNotificationsChipLight = useMemo(
    () => isNotificationDropdownOpen || unreadNotificationCount === 0,
    [isNotificationDropdownOpen, unreadNotificationCount]
  );

  const notificationsChipClasses = classnames(
    classes.notificationsChip,
    {
      [classes.notificationsChipLight]: isNotificationsChipLight,
    }
  );

  useEffect(() => {
    if (isLogged) {
      dispatch(getNotifications({
        page: 1,
        pageSize: 20,
      }));
      fetchInterval.current = window.setInterval(() => {
        if (!isNotificationDropdownOpen) {
          dispatch(getNotifications({
            page: 1,
            pageSize: 1,
          }));
        }
      }, 15000);
    }

    return () => clearInterval(fetchInterval.current);
  }, [
    dispatch,
    fetchInterval,
    isNotificationDropdownOpen,
    isLogged,
  ]);

  return (
    <div>
      { isNotificationDropdownOpen && (
        <NotificationDropdown
          anchorEl={anchorEl}
          handleClickAway={handleNotificationDropdownClose}
          notifications={notifications}
        />
      )}

      <Toolbar className={classes.toolbar}>
        {isLogged && (
          <>
            {hasBackButton && (
              <Button
                className={classes.goBackButton}
                onClick={handleGoToDashboard}
                startIcon={<ArrowBack />}
              >
                Go back to dashboard
              </Button>
            )}
            <span>
              <Button
                aria-label="notification"
                className={classes.notificationsButton}
                onClick={handleNotificationDropdownToggle}
              >
                <Chip
                  className={notificationsChipClasses}
                  color="primary"
                  variant={isNotificationsChipLight ? 'outlined' : 'default'}
                  icon={<NotificationsIcon fontSize="small" />}
                  label={unreadNotificationCount}
                />
              </Button>
              <Button
                color="inherit"
                endIcon={<KeyboardArrowDown />}
                startIcon={(
                  <img
                    alt="avatar icon"
                    src="/images/avatar.svg"
                  />
                )}
                onClick={handleMenuButtonClick}
                ref={menuButton}
              >
                <Typography
                  className={classes.userEmail}
                  variant="subtitle2"
                  data-testid="loginEmailDropdown"
                >
                  {user.email}
                </Typography>
              </Button>
              <Menu
                id="main-menu"
                onClose={handleMenuClose}
                open={isMenuVisible}
                anchorEl={menuButton.current}
                keepMounted
              >
                <div>
                  <GoogleLogout
                    onLogoutSuccess={handleLogout}
                    clientId={GOOGLE_CLIENT_ID}
                    render={renderProps => (
                      <MenuItem
                        onClick={renderProps.onClick}
                      >
                        Logout
                      </MenuItem>
                    )}
                  />
                </div>
              </Menu>
            </span>
          </>
        )}
      </Toolbar>
    </div>
  );
};
