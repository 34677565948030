import { makeStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles(theme => ({
  popper: {
    background: '#fff',
    boxShadow: `0 4px 8px ${fade(theme.palette.common.black, 0.08)}`,
    maxWidth: '400px',
    width: '100%',
    zIndex: 9999,
  },
  popperPaper: {
    maxHeight: '500px',
    overflowY: 'auto',
    padding: '20px',
  },
  text: {
    lineHeight: '24px',
    marginBottom: '4px',
  },
}));
