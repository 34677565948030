import {
  PaginationParams, WithId,
} from '@/core/interfaces/common';

export enum notificationType {
  WARNING = 'WRN',
  ERROR = 'ERR',
  INFO = 'INF',
}

export enum notificationTemplate {
  PROJECT_STALE = 'PROJECT_NOT_UPDATED_FOR_WEEK',

  MENTION_RISK = 'MENTION_RISK_LOG',
  MENTION_OPPORTUNITY = 'MENTION_OPPORTUNITY_LOG',

  COMMENT_RISK = 'PROJECT_NOTIFY_PM_ON_RISK_COMMENT',
  COMMENT_OPPORTUNITY = 'PROJECT_NOTIFY_PM_ON_OPP_COMMENT',
  COMMENT_OBSERVED_RISK = 'NOTIFY_OBSERVER_ON_RISK_COMMENT',
  COMMENT_OBSERVED_OPPORTUNITY = 'NOTIFY_OBSERVER_ON_OPP_COMMENT',

  UPDATE_OBSERVED = 'PROJECT_NOTIFY_OBSERVERS',
}

export interface SimpleNotification extends WithId {
  message: string;
  recipient: number;
  isRead: boolean;
  notificationType: notificationType;
  notificationTemplate: notificationTemplate;
  notificationSource: string;
  objectId: number;
  commentedObjectId: number | null;
  objectName: string;
  createdAt: string;
}

export interface NotificationParams extends PaginationParams {
  isRead: boolean;
}
