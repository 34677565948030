import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  wrapper: {
    bottom: '5vh',
    left: '5vh',
    position: 'fixed',
    'z-index': '10000',
  },
});
