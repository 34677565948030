import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './DrawerForm.styles';

interface DrawerFormProps {
  drawerSearchValue: string;
  isProjectList?: boolean;
  showObservedProjects: boolean;
  sortDirection: string;
  handleSearchChange: React.ChangeEventHandler<HTMLInputElement>;
  handleSearchClear: React.MouseEventHandler<HTMLButtonElement>;
  handleShowObserved: React.ChangeEventHandler<HTMLInputElement>;
  handleSort: SelectInputProps['onChange'];
}

export const DrawerForm = ({
  drawerSearchValue,
  handleSearchChange,
  handleSearchClear,
  handleShowObserved,
  handleSort,
  isProjectList = false,
  showObservedProjects,
  sortDirection,
}: DrawerFormProps) => {
  const classes = useStyles();

  return (
    <>
      {isProjectList && (
        <FormControlLabel
          className={classes.showObservedLabel}
          value="end"
          control={(
            <Checkbox
              checked={showObservedProjects}
              className={classes.tickSize}
              onChange={handleShowObserved}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          )}
          label="Show observed"
          labelPlacement="end"
        />
      )}
      <FormControl
        className={classes.formControl}
        size="small"
      >
        <Select
          className={classes.sortSelect}
          id="sort-select"
          onChange={handleSort}
          value={sortDirection}
          variant="outlined"
        >
          <MenuItem value="ascending">Sort: A-Z</MenuItem>
          <MenuItem value="descending">Sort: Z-A</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          data-testid="filter-name-input"
          placeholder="Name"
          onChange={handleSearchChange}
          size="small"
          value={drawerSearchValue}
          variant="outlined"
        />
        <IconButton
          className={classes.searchClear}
          size="small"
          onClick={handleSearchClear}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </FormControl>
    </>
  );
};
