import NextLink, { LinkProps as NextLinkProps } from 'next/link';

interface LinkProps extends NextLinkProps {
  children: React.ReactNode;
  className?: string;
}

export const Link = ({
  className = '',
  children,
  href,
  ...props
}: LinkProps) => (
  <NextLink
    href={href}
  >
    <a
      className={className}
      {...props}
    >
      {children}
    </a>
  </NextLink>
);
