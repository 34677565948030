import { useSelector } from 'react-redux';

import { selectAllGlobalMentions } from '../../store';
import { GlobalMessage } from '../GlobalMessage/GlobalMessage';
import { useStyles } from './GlobalMessagesContainer.styles';

export const GlobalMessagesContainer = () => {
  const { wrapper } = useStyles();
  const globalMessages = useSelector(selectAllGlobalMentions);

  return (
    <div className={wrapper}>
      {globalMessages.map(message => (
        <GlobalMessage
          key={message.id}
          {...message}
        />
      ))}
    </div>
  );
};
