import {
  forwardRef,
  RefObject,
  useCallback,
  useState,
} from 'react';
import {
  IconButton,
  ListItem,
} from '@material-ui/core';

import { Link } from '@/core/components/Link';
import { Confirm } from '@/core/components/Confirm';
import { useAppDispatch } from '@/core/store/store';
import { useDrawerContext } from '@/core/hooks/DrawerContextProvider';

import {
  getProjectNameList,
  setProjectObserve,
} from '@/features/Project/store';
import {
  getProjectManagersList,
} from '@/features/ProjectManager/store';

import { useStyles } from '../../Drawer.styles';

export interface ProjectListItemTypes {
  classNames: string;
  isObservedByUser: boolean;
  isPmAscending?: boolean;
  name: string;
  onClick?: () => void;
  projectId: number;
}

export const ProjectListItem = forwardRef(({
  classNames,
  isObservedByUser,
  isPmAscending = true,
  name,
  onClick,
  projectId,
}: ProjectListItemTypes, ref) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const {
    drawerPMSearchValue,
    drawerProjectSearchValue,
    showObservedProjects,
  } = useDrawerContext();

  const handleObserveClick = useCallback(evt => {
    evt.preventDefault();
    setConfirmOpen(true);
  }, []);

  const handleProjectObserve = async () => {
    await dispatch(setProjectObserve(projectId));

    dispatch(getProjectNameList({
      search: drawerProjectSearchValue,
      showOnlyObserved: showObservedProjects,
    }));

    dispatch(getProjectManagersList({
      ordering: isPmAscending ? 'full_name' : '-full_name',
      search: drawerPMSearchValue,
    }));
  };

  return (
    <>
      <ListItem
        className={classNames}
        component="li"
        onClick={onClick}
        ref={ref as RefObject<HTMLLIElement>}
        title={name}
      >
        <Link
          className={classes.itemLink}
          key={name}
          href={`/projects/${projectId}`}
        >
          {isObservedByUser && (
            <IconButton
              className={classes.starButton}
              onClick={handleObserveClick}
            >
              <img
                alt="star-filled"
                className={classes.starFill}
                src="/images/star-fill.svg"
              />
            </IconButton>
          )}

          {!isObservedByUser && (
            <IconButton
              className={classes.starButton}
              onClick={handleObserveClick}
            >
              <img
                alt="star-empty"
                className={classes.starEmpty}
                src="/images/star-empty.svg"
              />
            </IconButton>
          )}
          <span className={classes.itemText}>
            {name}
          </span>
        </Link>
      </ListItem>

      <Confirm
        content={`Do you want to ${isObservedByUser ? 'unobserve' : 'observe'} project ${name}?`}
        title="Confirm action"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleProjectObserve}
      />
    </>
  );
});
